/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  mdiPaperclip,
  mdiClose,
  mdiEmailPlusOutline,
  mdiSend,
  mdiFileDocument,
  mdiFilePdfBox,
  mdiInformationOutline,
  mdiChevronLeft,
  mdiChevronRight,
} from '@mdi/js';
import { Editor } from '@tinymce/tinymce-react';
import { toast } from 'react-toastify';
import { Input, Dropdown, Popup } from 'semantic-ui-react';
import Spinner from '../../../../components/Spinner/Spinner';
import { AccountContext } from '../../../../providers/AccountProvider';
import Icon from '@mdi/react';
import TextButton from '../../../../components/TextButton/TextButton';
import GBButton from '../../../../components/GBButton/GBButton';
import ActionButton from '../ActionBar/ActionButton';
import IconButton from '../FilterData/IconButton';
import FindAttachments from '../common/FindAttachments';
import { getMessageTemplatesAPI, constructEmail, getEmailTemplate } from '../../../../api/messages';
import TemplateViewer from './TemplateViewer';
import SendButton from './SendButton';
import MessageSettings from './MessageSettings';
import { updateUserTabSettings, getUserTabSettings } from '../../../../api/tables';
import ImageThumbnails from '../../../../components/ImageThumbnails/ImageThumbnails';
import CustomEditor from 'src/components/CustomEditor/CustomEditor';

const EmailControl = ({
  tableid,
  dataID,
  color,
  toAddress,
  emailField,
  close,
  sendMessage,
  role,
  emailRecords,
  replyMsg,
  tokens,
}) => {
  const { userInfo } = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showCC, setShowCC] = useState(false);
  const [showBCC, setShowBCC] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [showTemplates, setShowTemplates] = useState(false);
  const [showMessageSettings, setShowMessageSettings] = useState(false);
  const [initMessage, setInitMessage] = useState('');
  const [message, setMessage] = useState('');
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [messageSetting, setMessageSetting] = useState({ interval: 'none', number: 1 });
  const [fromName, setFromName] = useState(`${userInfo.firstName} ${userInfo.lastName}`);
  const [replyTo, setReplyTo] = useState(emailField.replyTo);
  const [subject, setSubject] = useState('');
  const [CC, setCC] = useState('');
  const [BCC, setBCC] = useState('');
  const [files, setFiles] = useState(null);
  const [pdfcontent, setPdfContent] = useState(null);
  const [templateId, setTemplateId] = useState(null); // Added this to support multi-email + template. In this mode, we send additional params so mergeContent is creating at time of sending.
  const [isPreviewGenerated, setIsPreviewGenerated] = useState(false);
  const [previewContent, setPreviewContent] = useState(null);
  const [previewId, setPreviewId] = useState(null);
  const [viewPdfContent, setViewPdfContent] = useState(null);
  const [deliveryAddress, setDeliveryAddress] = useState(toAddress ?? '');

  const SettingsIconPath =
    'M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z';

  const showPdfContent = (val) => {
    setViewPdfContent(val);
  };

  const toggleMessageSettings = () => {
    setShowMessageSettings(!showMessageSettings);
  };

  const toggleOption = (option) => {
    if (option == 'CC') {
      setShowCC(!showCC);
    } else {
      setShowBCC(!showBCC);
    }
  };

  const clearFields = () => {
    setMessage('');
    setSubject('');
    setReplyTo('');
    setCC('');
    setBCC('');
    setFiles([]);
    setPdfContent(null);
    setFromName('');
    setPdfContent(null);
  };

  useEffect(() => {
    if (emailField.selectedTable !== undefined) {
      const GetMessageTemplates = async () => {
        const response = await getMessageTemplatesAPI(emailField.selectedTable, null);
        setMessageTemplates(response);
        if (
          response.length > 0 &&
          emailField.selectedTemplate !== undefined &&
          replyMsg === null &&
          toAddress !== undefined
        ) {
          await processMessageTemplate(emailField.selectedTemplate);
        }
      };
      GetMessageTemplates();
    }

    const GetMessageOptions = async () => {
      const settingsResult = await getUserTabSettings(tableid);
      const userTabSettings = settingsResult[0];
      if (
        userTabSettings &&
        userTabSettings.tabsettings !== null &&
        userTabSettings.tabsettings.EmailMessageDelivery !== undefined
      ) {
        setMessageSetting(userTabSettings.tabsettings.EmailMessageDelivery);
      }
    };

    GetMessageOptions();
  }, [tableid]);

  useEffect(() => {
    if (replyMsg != null) {
      setDeliveryAddress(replyMsg.toaddress);
      setSubject(replyMsg.subject);
      setInitMessage(replyMsg.message);
      setMessage(replyMsg.message);
      setFromName(replyMsg.fromName);
      if (replyMsg.CC !== undefined) {
        setCC(replyMsg.CC);
        setShowCC(true);
      }
      if (replyMsg.BCC !== undefined) {
        setBCC(replyMsg.BCC);
        setShowBCC(true);
      }
      if (replyMsg.files !== undefined) {
        setFiles(replyMsg.files);
      }
    }
  }, [replyMsg]);

  const ShowImageInWindows = (item) => {
    window.open(item.url);
  };

  const updatePdfContent = (value) => {
    const currentviewPdfContent = viewPdfContent;
    currentviewPdfContent.value = value;
    setViewPdfContent(currentviewPdfContent);

    //update pdfcontent item in array.
    const idx = pdfcontent.findIndex((itm) => itm.name === currentviewPdfContent.name);
    if (idx !== 1) {
      pdfcontent[idx] = currentviewPdfContent;
    }
  };

  const createAndSendMessage = async () => {
    if ((subject === '' || fromName === '' || message == '') && !isPreviewGenerated) {
      toast.info(
        <div style={{ margin: '10px' }}>You must provide a subject, from name and message</div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: false,
        },
      );

      return false;
    }

    setIsLoading(true);
    let emailObjectArray = null;
    let templateFields = null;
    let ids = null;

    // 9-21-23
    // We will allow a user to manually enter tokens as well. Here we check if any of the defined
    //values contain the {{  characters, indicating a token is defiend. If yes, use template fields
    let hasTokens = replyTo.includes('{{') || subject.includes('{{') || message.includes('{{');

    //if toAddress is defined, we have a single email object, otherwise , sending multiple
    // SINGLE RECORD AND NO TOKENS INCLUDED
    if (toAddress !== undefined && !hasTokens) {
      emailObjectArray = [
        {
          fromAddress: emailField.fromAddress,
          id: dataID,
          toAddress,
          deliveryAddress,
          replyTo,
          systemReplyTo: emailField.replyTo,
          subject,
          fromName,
          message: message,
          CC,
          BCC,
          files,
          pdfcontent,
        },
      ];
    } else if (toAddress !== undefined && hasTokens) {
      //SINGLE EMAIL AND THEY ADDED TOKENS, WE NEED TO SPECIFUY TemplateFields and map ids
      templateFields = [
        { field: 'replyTo', fieldValue: replyTo },
        { field: 'deliveryAddress', fieldValue: deliveryAddress },
        { field: 'subject', fieldValue: subject },
        { field: 'fromName', fieldValue: fromName },
        { field: 'message', fieldValue: message },
        { field: 'CC', fieldValue: CC },
        { field: 'BCC', fieldValue: BCC },
        { field: 'files', fieldValue: files },
        { field: 'pdfcontent', fieldValue: pdfcontent },
      ];
      ids = [dataID];
    }

    // MULTI RECORD, NO TEMPLATE USED AND NO TOKENS MANUALLY ADDED
    if (emailRecords?.length > 0 && templateId === null && !hasTokens) {
      emailObjectArray = [];
      emailRecords.map((itm) => {
        emailObjectArray.push({
          fromAddress: emailField.fromAddress,
          deliveryAddress: itm.email,
          toAddress: itm.email,
          id: itm.id,
          replyTo,
          systemReplyTo: emailField.replyTo,
          subject,
          fromName,
          message: message,
          CC,
          BCC,
          files,
          pdfcontent,
        });
      });
    }

    // If we are in multi email and using a template BUT have not generated a preview,
    // pass template fields, ids, etc to sendEmailApi, where it will construct emailObjectArray prior to
    // sending email.

    // MULTI RECORDS, TEMPLATE USED OR TOKENS MANUALLY ADDED, BUT NO PREVIEW
    if (
      emailRecords &&
      emailRecords.length > 0 &&
      (templateId !== null || hasTokens) &&
      previewContent === null
    ) {
      templateFields = [
        { field: 'replyTo', fieldValue: replyTo },
        { field: 'deliveryAddress', fieldValue: deliveryAddress },
        { field: 'subject', fieldValue: subject },
        { field: 'fromName', fieldValue: fromName },
        { field: 'message', fieldValue: message },
        { field: 'CC', fieldValue: CC },
        { field: 'BCC', fieldValue: BCC },
        { field: 'files', fieldValue: files },
        { field: 'pdfcontent', fieldValue: pdfcontent },
      ];

      ids = emailRecords.map((itm) => itm.id);
    }

    // MULTI-RECORD, WITH TEMPLATE, PREVIEW GENERATED, SO USE/SEND ALREADY MERGED RECORDS
    if (emailRecords && emailRecords.length > 0 && templateId !== null && previewContent !== null) {
      emailObjectArray = previewContent;
    }

    const result = await sendMessage(
      emailObjectArray,
      messageSetting,
      templateFields,
      ids,
      userInfo.blocks,
      role,
    );

    setIsLoading(false);

    //if success, we close panel, otherwise, they will see toast message and can make changes to email
    //and try again.
    if (result === 'success') {
      close();
    }
  };

  const updateMsgSetting = async (msgSetting) => {
    const newsetting = {
      interval: msgSetting.interval,
      number: msgSetting.number,
    };

    const settingsResult = await getUserTabSettings(tableid);
    const userTabSettings = settingsResult[0];

    if (userTabSettings.tabsettings !== undefined) {
      userTabSettings.tabsettings.EmailMessageDelivery = newsetting;
    } else {
      userTabSettings.tabsettings = { EmailMessageDelivery: newsetting };
    }
    const method = userTabSettings.hasSettings ? 'update' : 'insert';
    await updateUserTabSettings(tableid, userTabSettings.tabsettings, method);
    setMessageSetting(newsetting);
  };

  const deleteFile = (file) => {
    const tempurls = [...files];
    const idx = files.findIndex((itm) => itm.filename === file.filename);
    tempurls.splice(idx, 1);
    setFiles(tempurls);
  };

  const handleInsertLinks = (Links) => {
    let newFiles = [];
    if (files === null) {
      newFiles = [];
    } else {
      newFiles = structuredClone(files);
    }
    Links.map((itm) => {
      const idx = newFiles.findIndex((el) => el.url === itm.url);
      if (idx === -1) {
        newFiles.push({ url: itm.url, filename: itm.filename, guidname: itm.filename });
      }
    });
    setFiles(newFiles);
  };

  const getBodyHeight = () => {
    let removeHeight = 0;
    if (showCC) {
      removeHeight = removeHeight + 30;
    }
    if (showBCC) {
      removeHeight = removeHeight + 30;
    }
    const finalHeight = 410 + removeHeight;
    return `calc(100vh - ${finalHeight}px)`;
  };

  const retrieveTemplate = async (val) => {
    const templateInfo = {
      blockid: emailField.selectedBlock,
      tableid: emailField.selectedTable,
      rowid: val,
    };
    const templateData = await getEmailTemplate(tableid, templateInfo, userInfo.blocks, role);

    //9-10-24 get selected template, set in state, so we can show name (fullname) above
    //record selection.
    const idx = messageTemplates.findIndex((itm) => itm.id === val);
    if (idx !== -1) {
      setSelectedTemplate(messageTemplates[idx]);
    }

    setFieldValues(templateData.emailFields);
    setFiles(templateData.files);
    setPdfContent(templateData.pdfContent);
    setTemplateId(val);
    setShowTemplates(false);
  };

  const processMessageTemplate = async (val) => {
    //define template info used to lookup all the template fields
    const templateInfo = {
      blockid: emailField.selectedBlock,
      tableid: emailField.selectedTable,
      rowid: val,
    };
    const content = await constructEmail(tableid, [dataID], templateInfo, userInfo.blocks, role);

    // const idx = messageTemplates.findIndex(itm=>itm.id===val)

    if (content[0].emailFields.length > 0) {
      setFiles(content[0].files);
      setPdfContent(content[0].pdfContent);
      setFieldValues(content[0].emailFields);
    }
    setShowTemplates(false);
  };

  const generatePreview = async () => {
    setIsLoading(true);
    let ids = emailRecords.map((itm) => itm.id);

    const templateFields = [
      { field: 'replyTo', fieldValue: replyTo },
      { field: 'deliveryAddress', fieldValue: deliveryAddress },
      { field: 'subject', fieldValue: subject },
      { field: 'fromName', fieldValue: fromName },
      { field: 'message', fieldValue: message },
      { field: 'CC', fieldValue: CC },
      { field: 'BCC', fieldValue: BCC },
      { field: 'files', fieldValue: files },
      { field: 'pdfcontent', fieldValue: pdfcontent },
      { field: 'To', fieldValue: deliveryAddress },
    ];

    const content = await constructEmail(tableid, ids, null, userInfo.blocks, role, templateFields);

    setPreviewContent(content);
    setIsLoading(false);
    setIsPreviewGenerated(true);

    //Clear existing fields. They will need to select a record, which then is edited and can
    //update the values in array.
    clearFields();
  };

  const previewOptions = () => {
    let options = [];
    if (previewContent !== null) {
      previewContent.map((itm) => {
        options.push({
          key: itm.id,
          value: itm.id,
          text: (
            <span style={{ color: itm.hasError ? 'red' : 'black' }}>
              {itm.toAddress === itm.deliveryAddress
                ? itm.deliveryAddress
                : `${itm.deliveryAddress} about ${itm.toAddress}`}
            </span>
          ),
        });
      });
    }
    return options;
  };

  const selectPreviewRecord = (id) => {
    if (id === '') {
      setPreviewId(null);
      clearFields();
      return false;
    }

    setPreviewId(id);

    const previewRecord = previewContent.filter((itm) => itm.id === id)[0];
    setFromName(previewRecord.fromName);
    setBCC(previewRecord.BCC);
    setCC(previewRecord.CC);
    setReplyTo(previewRecord.replyTo);
    setSubject(previewRecord.subject);
    //  setMessage(previewRecord.message);
    setInitMessage(previewRecord.message);
    setFiles(previewRecord.files);
    setPdfContent(previewRecord.pdfcontent);
  };

  const saveUpdatedRecord = () => {
    const previewRecord = previewContent.filter((itm) => itm.id === previewId)[0];
    let hasError = false;
  
    if (
      fromName?.includes('{{') ||
      CC?.includes('{{') ||
      BCC?.includes('{{') ||
      subject?.includes('{{') ||
      replyTo?.includes('{{') ||
      pdfcontent?.map(itm=>itm.value).join().includes('{{') ||
      message?.includes('{{')
    ) {
      hasError = true;
    }
    previewRecord.hasError = hasError;
    previewRecord.fromName = fromName;
    previewRecord.CC = CC;
    previewRecord.BCC = BCC;
    previewRecord.subject = subject;
    previewRecord.files = files;
    previewRecord.replyTo = replyTo;
    previewRecord.pdfcontent = pdfcontent;
    previewRecord.message = message;
    previewRecord.To = deliveryAddress;

    toast.info(
      <div style={{ margin: '10px' }}>The record was updated!</div>,
      {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: true,
      },
    );

    // clearFields();
    // setPreviewId(null);
  };

  const NextPrev = (direction) => {
    const idx = previewContent.findIndex(itm=>itm.id===previewId);
    
    if(idx > 0 && direction==='prev') {
      selectPreviewRecord(previewContent[idx-1].id)
    } else if (idx >= 0 && direction==='next' && idx+1 < previewContent.length) {
      selectPreviewRecord(previewContent[idx+1].id)
    }
  }

  const setFieldValues = (items) => {
    for (const itm of items) {
      if (itm.header === 'To') {
        setDeliveryAddress(itm.fieldValue);
      } else if (itm.header === 'From name') {
        setFromName(itm.fieldValue);
      } else if (itm.header === 'Email body') {
        setInitMessage(itm.fieldValue);
        setMessage(itm.fieldValue);
      } else if (itm.header === 'Email subject') {
        setSubject(itm.fieldValue);
      } else if (itm.header === 'CC') {
        setCC(itm.fieldValue);
        setShowCC(true);
      } else if (itm.header === 'BCC') {
        setBCC(itm.fieldValue);
        setShowBCC(true);
      } else if (itm.header === 'Reply to email') {
        setReplyTo(itm.fieldValue);
      }
    }
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          padding: '10px',
          flexDirection: 'row',
          height: '40px',
          backgroundColor: color,
          color: 'white',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        }}
      >
        <div
          style={{
            marginLeft: '10px',
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Icon path={mdiEmailPlusOutline} size="25px" />
          <div style={{ marginLeft: '5px', fontSize: '15px' }}>Compose email </div>
        </div>
        <TextButton
          text="Cancel"
          Action={close}
          textColor="white"
          icon={mdiClose}
          hoverColor="#FFFFFF80"
        />
      </div>

      {isLoading ? (
        <Spinner color={color} />
      ) : (
        <div
          style={{
            padding: '10px',
            height: 'calc(100vh - 180px)',
            overflow: 'auto',
            position: 'relative',
          }}
        >
          {/* Show message template icon */}
          {messageTemplates.length > 0 && templateId === null ? (
            <div style={{ marginLeft: '115px' }}>
              <GBButton
                text="Select a template"
                iconPosition="left"
                iconColor="white"
                iconHoverColor="#FFFFFF80"
                iconSize="20px"
                color={color}
                hoverColor={`${color}1A`}
                icon={mdiFileDocument}
                Action={() => setShowTemplates(!showTemplates)}
              />
            </div>
          ) : null}
          {templateId !== null && !isPreviewGenerated ? (
            <div style={{ marginLeft: '115px' }}>
              <GBButton
                color={color}
                text="Generate preview"
                width="140px"
                Action={generatePreview}
              />
            </div>
          ) : null}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'relative',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                margin: '10px',
              }}
            >
              <div style={{ textAlign: 'right', width: '100px', marginRight: '5px' }}>From:</div>
              <div>{emailField.fromAddress}</div>
              <div style={{ marginLeft: '10px', marginBottom: '3px' }}>
                <IconButton
                  tooltip="Click to learn more."
                  size="20px"
                  color={color}
                  hoverColor={`${color}80`}
                  icon={mdiInformationOutline}
                  Action={() =>
                    window.open(
                      'https://www.graceblocks.com/support-article/custom-from-email-addresses-support',
                    )
                  }
                />
              </div>

              {!showCC ? (
                <div style={{ marginLeft: '20px' }}>
                  <TextButton
                    textColor={color}
                    hoverColor={`${color}80`}
                    text="CC"
                    Action={toggleOption}
                    ActionValue="CC"
                  />{' '}
                </div>
              ) : null}

              {!showBCC ? (
                <div style={{ marginLeft: '20px' }}>
                  <TextButton
                    textColor={color}
                    hoverColor={`${color}80`}
                    text="BCC"
                    Action={toggleOption}
                    ActionValue={'BCC'}
                  />{' '}
                </div>
              ) : null}
            </div>

            {tokens !==undefined ? (
                  <div style={{position: 'absolute', top: 10, right: 10, zIndex: 100,display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <TextButton
                    text="Cancel"
                    Action={close}
                    textColor="black"
                    // icon={mdiClose}
                    hoverColor="#00000080"
                  />
                  <div style={{width:'10px'}}/>
                  <GBButton padding='10px' color={color} width="75px" text="Save" Action={createAndSendMessage} />
                  </div>
                ):null}

            {previewContent !== null ? (
              <div style={{ position: 'absolute', top: 10, right: 50, zIndex: 100 }}>
                <div>Preview options for template: {selectedTemplate.fullname}</div>
                <Dropdown
                  search
                  placeholder="select a record to preview/update"
                  selectOnBlur={false}
                  options={previewOptions()}
                  selection
                  value={previewId}
                  onChange={(e, data) => selectPreviewRecord(data.value)}
                />
                <div style={{ height: '10px' }}></div>
                {previewId !== null ? (
                  <>
                    <GBButton
                      color={color}
                      width="210px"
                      Action={saveUpdatedRecord}
                      text="Update record"
                    />
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',marginTop:'10px' }}>
                    <GBButton
                      color={color}
                      width="100px"
                      iconColor='white'
                      iconHoverColor="#FFFFFF80"
                      iconSize='20px'
                      icon={mdiChevronLeft}
                      iconPosition='left'
                      Action={()=>NextPrev('prev')}
                      text="Prev"
                    />
                      <div style={{ width: '10px' }} />
                      <GBButton
                      color={color}
                      width="100px"
                      iconColor='white'
                      iconHoverColor="#FFFFFF80"
                      iconSize='20px'
                      icon={mdiChevronRight}
                      iconPosition='right'
                      Action={()=>NextPrev('next')}
                      text="Next"
                    />
                    </div>
                  </>
                ) : null}
                
                <div style={{ height: '10px' }}></div>
                {/* This shows pdf content for multi-records */}

                <div>
                  {pdfcontent !== null &&
                    pdfcontent.map((itm) => (
                      <div>
                        <TextButton
                          icon={mdiFilePdfBox}
                          iconPosition="left"
                          textColor={color}
                          hoverColor={`${color}80`}
                          text={itm.name}
                          Action={() => showPdfContent(itm)}
                        />
                      </div>
                    ))}
                </div>
              </div>
            ) : null}

            {/* This is to show pdfcontent link for single record */}
            <div style={{ height: '10px' }}></div>
            {pdfcontent !== null && previewContent === null ? (
              <div>
                {pdfcontent.map((itm) => (
                  <div>
                    <TextButton
                      icon={mdiFilePdfBox}
                      iconPosition="left"
                      textColor={color}
                      hoverColor={`${color}80`}
                      text={itm.name}
                      Action={() => showPdfContent(itm)}
                    />
                  </div>
                ))}
              </div>
            ) : null}

            {viewPdfContent !== null ? (
              <div
                style={{
                  position: 'fixed',

                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 100,
                  backgroundColor: 'white',
                  width: '70%',
                  height: 'calc(100vh - 300px)',
                  border: '1px solid #aaa',
                  boxShadow: '0px 3px 6px #707070',
                  borderRadius: '10px',
                }}
              >
                {' '}
                <div
                  style={{
                    display: 'flex',
                    padding: '10px',
                    flexDirection: 'row',
                    height: '40px',
                    backgroundColor: color,
                    color: 'white',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                  }}
                >
                  <div
                    style={{
                      marginLeft: '10px',
                      color: 'white',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Icon path={mdiFileDocument} size="25px" />
                    <div style={{ marginLeft: '5px', fontSize: '15px' }}>Pdf content </div>
                  </div>
                  <TextButton
                    text="Hide panel when done"
                    Action={() => setViewPdfContent(null)}
                    textColor="white"
                    icon={mdiClose}
                    hoverColor="#FFFFFF80"
                  />
                </div>
                <div
                  onInput={(e) => updatePdfContent(e.target.innerHTML)}
                  contentEditable="true"
                  style={{ overflow: 'auto', height: 'calc(100vh - 360px)' }}
                  dangerouslySetInnerHTML={{ __html: viewPdfContent.value }}
                />
              </div>
            ) : null}
          </div>

          <div
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px' }}
          >
            <div style={{ textAlign: 'right', width: '100px', marginRight: '5px' }}>To:</div>
            <div>
              {(toAddress !== undefined && toAddress === deliveryAddress) ||
              toAddress === '{{dynamic}}'
                ? toAddress
                : null}
              {toAddress !== undefined &&
              toAddress !== deliveryAddress &&
              toAddress !== '{{dynamic}}'
                ? `${deliveryAddress} about ${toAddress}`
                : null}
              {emailRecords && emailRecords.length > 0 && !deliveryAddress.includes('{{') ? (
                <Popup
                  className="custom-popup-content"
                  hoverable
                  content={emailRecords.map((itm) => itm.email).join('\n')}
                  trigger={<div>{emailRecords.length} records</div>}
                />
              ) : null}
              {emailRecords && emailRecords.length > 0 && deliveryAddress.includes('{{') ? (
                <Popup
                  className="custom-popup-content"
                  hoverable
                  content={deliveryAddress}
                  trigger={<div>{emailRecords.length} records</div>}
                />
              ) : null}
            </div>
          </div>

          <div
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px' }}
          >
            <div style={{ textAlign: 'right', width: '100px', marginRight: '5px' }}>From name:</div>
            <div>
              <Input
                style={{ width: '300px' }}
                value={fromName}
                onChange={(e, data) => setFromName(data.value)}
                fluid
              />
            </div>
          </div>

          {emailField.replyConfiguration === undefined ||
          emailField.replyConfiguration === 'general' ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                margin: '10px',
              }}
            >
              <div style={{ textAlign: 'right', width: '100px', marginRight: '5px' }}>
                Reply to:
              </div>
              <div>
                <Input
                  style={{ width: '300px' }}
                  value={replyTo}
                  onChange={(e, data) => setReplyTo(data.value)}
                  fluid
                />
                <span style={{ fontSize: '10px' }}>
                  (If replyto is not from system defined parse.graceblocks.com, replys will not
                  poppulate back into system)
                </span>
              </div>
            </div>
          ) : null}

          <div
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px' }}
          >
            <div style={{ textAlign: 'right', width: '100px', marginRight: '5px' }}>Subject:</div>
            <div>
              <Input
                style={{ width: '300px' }}
                value={subject}
                onChange={(e, data) => setSubject(data.value)}
                fluid
              />
            </div>
          </div>

          {showCC ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                margin: '10px',
              }}
            >
              <div style={{ textAlign: 'right', width: '100px', marginRight: '5px' }}>CC:</div>
              <div>
                <Input
                  style={{ width: '300px' }}
                  value={CC}
                  onChange={(e, data) => setCC(data.value)}
                  fluid
                />
              </div>
            </div>
          ) : null}
          {showBCC ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                margin: '10px',
              }}
            >
              <div style={{ textAlign: 'right', width: '100px', marginRight: '5px' }}>BCC:</div>
              <div>
                <Input
                  style={{ width: '300px' }}
                  value={BCC}
                  onChange={(e, data) => setBCC(data.value)}
                  fluid
                />
              </div>
            </div>
          ) : null}

          <div style={{ border: '1px solid #E5E5E6', borderRadius: '7px' }}>
            <CustomEditor
              initValue={initMessage}
              updateContent={setMessage}
              height={getBodyHeight()}
              callingComponent="EmailControl"
              tokens={tokens}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: '10px',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <IconButton
                size="25px"
                tooltip="Attach files"
                color={color}
                hoverColor={`${color}80`}
                icon={mdiPaperclip}
                Action={() => setShowAttachments(!showAttachments)}
              />
              <div style={{ width: '400px' }}>
                <ImageThumbnails
                  items={files}
                  maxWidth="50px"
                  Action={ShowImageInWindows}
                  deleteAction={deleteFile}
                  showDeleteIcon={true}
                />
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {tokens === undefined ? (
                <TextButton
                  textColor="black"
                  hoverColor={'#00000080'}
                  text="Message settings"
                  iconSize="25px"
                  icon={SettingsIconPath}
                  Action={toggleMessageSettings}
                />
              ) : null}
              <div style={{ width: '15px' }} />
              {tokens !==undefined ? (
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <TextButton
                  text="Cancel"
                  Action={close}
                  textColor="black"
                  // icon={mdiClose}
                  hoverColor="#00000080"
                />
                <div style={{width:'10px'}}/>
                <GBButton padding='10px' color={color} width="75px" text="Save" Action={createAndSendMessage} />
                </div>
              ) : <SendButton
              iconPosition="right"
              color={color}
              icon={mdiSend}
              Action={createAndSendMessage}
              text={messageSetting.interval === 'none'  ? 'Send now' : `Send in ${messageSetting.number} ${messageSetting.interval}`}
              width={messageSetting.interval === 'none'  ? '130px' : '170px' }
            /> }
              
            </div>
          </div>
        </div>
      )}
      {showAttachments ? (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 120,
            backgroundColor: 'white',
            width: '70%',
            height: '600px',
            border: '1px solid #aaa',
            boxShadow: '0px 3px 6px #707070',
            borderRadius: '10px',
          }}
        >
          <FindAttachments
            color={color}
            close={() => setShowAttachments(false)}
            handleInsertLinks={handleInsertLinks}
          />
        </div>
      ) : null}

      {showTemplates ? (
        <div
          style={{
            position: 'fixed',
            zIndex: 120,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            border: '1px solid #aaa',
            backgroundColor: 'white',
            width: '510px',
            height: '400px',
          }}
        >
          <TemplateViewer
            appendTemplateMessage={
              toAddress !== undefined ? processMessageTemplate : retrieveTemplate
            }
            showTemplates={setShowTemplates}
            messageTemplates={messageTemplates}
            color={color}
            callingComponent="emailMessages"
          />
        </div>
      ) : null}

      {showMessageSettings ? (
        <div
          style={{
            position: 'absolute',
            top: 270,
            right: 120,
            zIndex: 120,
            backgroundColor: 'white',
            boxShadow: '0px 2px 5px #707070',
            border: '1px solid #aaa',
            borderRadius: '10px',
            // height:'200px'
          }}
        >
          <MessageSettings
            color={color}
            close={toggleMessageSettings}
            messageSetting={messageSetting}
            updateMessageSetting={updateMsgSetting}
          />
        </div>
      ) : null}
    </div>
  );
};

export default EmailControl;
