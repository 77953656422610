/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import { Label, Popup } from 'semantic-ui-react';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import moment from 'moment';

const FilterTags = (props) => {
  // useEffect(() => {
  //   console.log(props)
  // })

  // This can be called from LookupConfig page. If I pass in removeFilter=null, this means it's coming
  // from lookukp page nad should display tags vertically instead of horizontally.

  const FilterDisplay = (filter) => {
    let opString = '';
    let displayvalue = '';
    let popupvalue = `${filter.header} ${opString}: ${filter.value}`;

    switch (filter.operator) {
      case 'contains':
        opString = '(c)';
        displayvalue = filter.value;
        popupvalue = `${filter.header} ${opString}: ${filter.value}`;
        break;

      case 'startswith':
        opString = '(sw)';
        displayvalue = filter.value;
        popupvalue = `${filter.header} ${opString}: ${filter.value}`;
        break;

      case 'notcontains':
        opString = '(nc)';
        displayvalue = filter.value;
        popupvalue = `${filter.header} ${opString}: ${filter.value}`;
        break;

      case 'equalto':
        opString = ': ';
        displayvalue = filter.value;
        popupvalue = `${filter.header} ${opString} ${filter.value}`;
        break;
      case 'notequalto':
        opString = ': ';
        displayvalue = filter.value;
        popupvalue = `${filter.header} ${opString} ${filter.value}`;
        break;
      case 'isnull':
        opString = '';
        displayvalue = 'is empty';
        popupvalue = `${filter.header} ${displayvalue}`;
        break;
      case 'isnotnull':
        opString = '';
        displayvalue = 'is not empty';
        popupvalue = `${filter.header} ${displayvalue}`;
        break;
      case 'fromto':
        opString = '(from-to)';
        displayvalue = `${moment(filter.startDate).format('DD-MMM-YYYY')}-${moment(
          filter.endDate,
        ).format('DD-MMM-YYYY')}`;
        popupvalue = `${filter.header} ${opString}: ${displayvalue}`;
        break;
      case 'specificdate':
        opString = ': ';
        displayvalue = moment(filter.startDate).format('DD-MMM-YYYY');
        popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        break;
      case 'before':
        opString = '(before)';
        displayvalue = moment(filter.startDate).format('DD-MMM-YYYY');
        popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        break;
      case 'incoming-outgoing':
        opString = '';
        displayvalue = filter.value;
        popupvalue = `${filter.header} ${displayvalue}`;
        break;
      case 'after':
        opString = '(after)';
        displayvalue = moment(filter.startDate).format('DD-MMM-YYYY');
        popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        break;
      case 'timeframe':
        opString = '(timeframe)';
        // eslint-disable-next-line no-case-declarations
        const tf = [24, 48, 72];
        if (tf.includes(filter.timeframe)) {
          displayvalue = `Last ${filter.timeframe} hours`;
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        // eslint-disable-next-line no-case-declarations
        const tf2 = [168, 336, 720, 2160, 4320, 8640];
        if (tf2.includes(filter.timeframe)) {
          displayvalue = `Last ${filter.timeframe / 24} days`;
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        if (filter.timeframe === 'thisquarter') {
          displayvalue = 'This quarter';
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        if (filter.timeframe === 'lastquarter') {
          displayvalue = 'Last quarter';
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        if (filter.timeframe === 'thisandlastquarter') {
          displayvalue = 'This & Last Quarter';
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        if (filter.timeframe === 'ytd') {
          displayvalue = 'Year to Date';
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        if (filter.timeframe === 'lastyear') {
          displayvalue = 'Last year';
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        if (filter.timeframe === 'past') {
          displayvalue = 'In the past';
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        if (filter.timeframe === 'future') {
          displayvalue = 'In the future';
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        if (filter.timeframe === '15min') {
          displayvalue = 'Next 15 minutes';
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        if (filter.timeframe === 'nexthour') {
          displayvalue = 'Next hour';
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        if (filter.timeframe === 'next24hours') {
          displayvalue = 'Next 24 hours';
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        if (filter.timeframe === 'next48hours') {
          displayvalue = 'Next 48 hours';
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        if (filter.timeframe === 'next72hours') {
          displayvalue = 'Next 72 hours';
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        if (filter.timeframe === 'next7days') {
          displayvalue = 'Next 7 days';
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        if (filter.timeframe === 'next14days') {
          displayvalue = 'Next 14 days';
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        if (filter.timeframe === 'next30days') {
          displayvalue = 'Next 30 days';
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        if (filter.timeframe === 'next90days') {
          displayvalue = 'Next 90 days';
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        if (filter.timeframe === 'next365days') {
          displayvalue = 'Next 365 days';
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        if (filter.timeframe === 'nextquarter') {
          displayvalue = 'Next quarter';
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        if (filter.timeframe === 'thisandnext') {
          displayvalue = 'This and next quarter';
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        if (filter.timeframe === 'untilyearend') {
          displayvalue = 'Until end of year';
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }
        if (filter.timeframe === 'nextyear') {
          displayvalue = 'Nexy year';
          popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        }


        break;

      case 'searchvalues':
        if (filter.textvalues.length > 0) {
          opString = ': ';
          displayvalue += filter.textvalues.join().substring(0, 35);
          // popupvalue = `${filter.header}: (not) ${filter.textvalues.join()}`;
          const vals =filter.textvalues.map(el=>(<>
            <br/>{el}
          </>))
          popupvalue=<div>{filter.header} (or) {vals}</div>
        }
        if (filter.roleValue && filter.roleValue.length > 0) {
          displayvalue += ' Role: ' + filter.roleValue.join().substring(0, 35);
        }
        break;

        case 'searchvalues_and':
        if (filter.textvalues.length > 0) {
          opString = ': ';
          displayvalue += filter.textvalues.join().substring(0, 35);
          // popupvalue = `${filter.header}: (not) ${filter.textvalues.join()}`;
          const vals =filter.textvalues.map(el=>(<>
            <br/>{el}
          </>))
          popupvalue=<div>{filter.header} (and) {vals}</div>
          
        }
        if (filter.roleValue && filter.roleValue.length > 0) {
          displayvalue += ' Role: ' + filter.roleValue.join().substring(0, 35);
        }
        break;

      case 'status':
        displayvalue=filter.value;
        popupvalue=filter.value;
        break;

      case 'interaction':
        displayvalue=filter.value;
        popupvalue=filter.value;
        break;

      case 'error':
        displayvalue=filter.value;
        popupvalue=filter.value;
        break;

        case 'doesnotinclude':
        if (filter.textvalues.length > 0) {
          opString = ': (not)';
          displayvalue += filter.textvalues.join().substring(0, 35);
          // popupvalue = `${filter.header}: (not) ${filter.textvalues.join()}`;
          const vals =filter.textvalues.map(el=>(<>
           <br/>{el}
          </>))
          popupvalue=<div>{filter.header} (not - or) {vals}</div>
        }
        if (filter.roleValue && filter.roleValue.length > 0) {
          displayvalue += ' Role: ' + filter.roleValue.join().substring(0, 35);
        }
        break;

        case 'doesnotinclude_and':
        if (filter.textvalues.length > 0) {
          opString = ': (not)';
          displayvalue += filter.textvalues.join().substring(0, 35);
          // popupvalue = `${filter.header}: (not) ${filter.textvalues.join()}`;
          const vals =filter.textvalues.map(el=>(<>
           <br/>{el}
          </>))
          popupvalue=<div>{filter.header} (not - and) {vals}</div>
        }
        if (filter.roleValue && filter.roleValue.length > 0) {
          displayvalue += ' Role: ' + filter.roleValue.join().substring(0, 35);
        }
        break;

      case 'minmax':
        opString = '(min-max)';
        displayvalue = `${filter.startNumber} - ${filter.endNumber}`;
        popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        break;
      case 'gt':
        opString = ' > ';
        displayvalue = filter.startNumber;
        popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        break;
      case 'lt':
        opString = ' < ';
        displayvalue = filter.startNumber;
        popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        break;
      case 'eq':
        opString = ': ';
        displayvalue = filter.startNumber;
        popupvalue = `${filter.header} ${opString} ${displayvalue}`;
        break;
      default:
        opString = '';
    }

    const textvalue = `${filter.header.substring(0, 25)} ${opString}`;
    // eslint-disable-next-line react/prop-types
    const { removeFilter } = props;

    return (
      <Popup
        key={filter.field}
        content={popupvalue}
        hoverable
        mouseEnterDelay={500}
        trigger={
          <div
            onClick={removeFilter !==null ? () => removeFilter(filter) : null}
            css={{
              display: 'flex',
              cursor:'pointer',
              backgroundColor:'#E8E8E8',
              flexDirection:'row',
              marginLeft: removeFilter !==null ?  '5px' : '0px',
              marginBottom:  removeFilter !==null ?  '0px' : '5px',
              width:'max-content',
              paddingTop:'1px',
              paddingBottom:'1px',
              whiteSpace:'nowrap',
              paddingLeft:'5px',
              paddingRight:'5px',
              borderRadius:'15px',
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                boxShadow: '0px 3px 6px #757575',
                transition: 'all .5s ease',
              },
            }}
          >
            <div>{textvalue}</div>
            <div style={{ color: '#669CF6', marginLeft: '2px' }}>
              {displayvalue.toString().substring(0, 35)}
            </div>
            {removeFilter !==null ? (
            <Icon path={mdiClose} size="15px" color="#E06055" />
            ): null}
          </div>
        }
      />
    );
  };

  const DisplayTags = () => {
    const { filters } = props;
    // console.log(filters);
    if (filters !== null) {
      return props.filters && filters.map((filter) => filter.header && FilterDisplay(filter));
    }
    return null;
  };

  const SortTags = () => {
    const { sortHeader, sortOrder, removeSort,sortMultipleFields } = props;
    let popupvalue = `Sort: ${sortHeader} ${sortOrder}`;
    let sHeader=sortHeader;
    let sOrder=sortOrder;

    if(sortMultipleFields !==undefined) {
      popupvalue=sortMultipleFields.map(itm=>`${itm.sortHeader} ${itm.sortOrder}`).join();
    }
    
    //12-1-22 if only one item in sortmultiple, show same header as normal.
    if(sortMultipleFields !==undefined && sortMultipleFields.length===1) {
      sHeader=sortMultipleFields[0].sortHeader;
      sOrder=sortMultipleFields[0].sortOrder;
    }
    
    return (
      <Popup
        key={sortHeader}
        content={popupvalue}
        hoverable
        mouseEnterDelay={500}
        trigger={
            <div onClick={() => removeSort()} 
            css={{
              display: 'flex',
              backgroundColor:'#E8E8E8',
              cursor:'pointer',
              flexDirection:'row',
              marginLeft:'5px',
              width:'max-content',
              paddingTop:'1px',
              paddingBottom:'1px',
              paddingLeft:'5px',
              paddingRight:'5px',
              borderRadius:'15px',
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                boxShadow: '0px 3px 6px #757575',
                transition: 'all .5s ease',
              },
            }}
            >
              Sort:
              <div style={{ color: '#669CF6', marginLeft: '2px',whiteSpace:'nowrap' }}>
               {sortMultipleFields ===undefined  || sortMultipleFields.length===1 ? (
                 `${sHeader?.substring(0, 10) ?? 'field'}: ${sOrder}`
               ): 'Multi-sort'}
               
              </div>
              <Icon path={mdiClose} size="15px" color="#E06055" />
            </div>
        }
      />
    );
  };

  const { filters, sortHeader,removeFilter,sortMultipleFields,width } = props;

  return (
    <div style={{ display:'flex', overflow: filters?.length>2 ? 'auto' : 'hidden', fontWeight:removeFilter !==null ? 'normal' : 'bold', flexDirection: removeFilter !==null ? 'row' : 'column',alignItems: removeFilter !==null ? 'center' :'flex-start', margin: removeFilter !==null ? '5px' :'0px',width:width !==undefined ? width : 'calc(100vw - 825px)' }}>
      <span style={{whiteSpace:'nowrap'}}>Filter criteria:</span> {(sortHeader !== 'id' && sortHeader !== 'userid') || sortMultipleFields !==undefined  ? SortTags() : null}{' '}
      {filters && filters.length === 0 ? '' : DisplayTags()}
      {/* {DisplayTags()} */}
    </div>
  );
};

export default FilterTags;
